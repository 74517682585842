header {
	background:#215D50;
	width: 100%; 
	
	z-index: 2000;

	.center-ber {
		padding: 15px;
		margin-top:0;
	}

	.bar-menu {
		cursor:pointer;
	}

	@media only screen and (max-width: 991px) {
		background: rgba(#215D50, 0);
		box-shadow: none; 
		padding-top:0;
		padding-bottom:0;
		position: absolute;
		top:0;

		.center-ber {
			display: flex;
			justify-content: space-between;
        	align-items: center;
        	
    	    
		}

		&.sticky {
			background: rgba(#215D50, 0.8); 
			position: fixed;
			z-index: 1000;

			.top-bar {
				display:none;
			}

		}
	}


	
}