header {
  background: #215D50;
  width: 100%;
  z-index: 2000;
}
header .center-ber {
  padding: 15px;
  margin-top: 0;
}
header .bar-menu {
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  header {
    background: rgba(33, 93, 80, 0);
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
  }
  header .center-ber {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header.sticky {
    background: rgba(33, 93, 80, 0.8);
    position: fixed;
    z-index: 1000;
  }
  header.sticky .top-bar {
    display: none;
  }
}

.mobile-nav {
  background: #215D50;
  padding: 20px 30px 20px;
  z-index: 1100;
}
.mobile-nav a#res-cross {
  position: absolute;
  top: 15px;
  right: 20px;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
}
.mobile-nav a#res-cross:hover {
  color: #fcba03;
}
.mobile-nav a#res-cross:before {
  display: none;
}
.mobile-nav > ul > li > a {
  color: #fff;
}
.mobile-nav > ul > li > a:hover {
  color: #fcba03;
}
@media only screen and (max-width: 991px) {
  .mobile-nav {
    max-width: 330px;
  }
}

.nav-bar {
  background: #fcba03;
}
.nav-bar .navbar-links li {
  padding: 8px 45px 8px 0;
}
.nav-bar .navbar-links li:hover > a {
  border-bottom: 3px solid #215D50;
}

nav.navbar > a {
  color: #000;
}
nav.navbar > a svg {
  fill: #215d50;
}

.home-banner {
  position: relative;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  background: url("../img/home-banner-img.jpg") center center no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0.6;
  z-index: 1;
}
.home-banner .banner-content {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.home-banner .banner-content h1, .home-banner .banner-content h2, .home-banner .banner-content > span {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-weight: bold;
  z-index: 100;
}
.home-banner .banner-content h1 > span, .home-banner .banner-content h2 > span, .home-banner .banner-content > span > span {
  position: relative;
  z-index: 100;
  box-shadow: inset 0 -0.4em 0 0 #FCBA03;
}
.home-banner .banner-content h1 > a, .home-banner .banner-content h2 > a, .home-banner .banner-content > span > a {
  background: #FCBA03;
  display: inline-block;
  padding: 10px 25px;
  font-size: 40px;
  border-radius: 8px;
}
.home-banner .banner-content h1 > a:hover, .home-banner .banner-content h2 > a:hover, .home-banner .banner-content > span > a:hover {
  color: #fff;
  background: #215D50;
}
.home-banner .banner-content h1 {
  margin-bottom: 30px;
  font-size: 60px;
}
.home-banner .banner-content h2 {
  margin-bottom: 40px;
  font-size: 50px;
}
@media only screen and (max-width: 991px) {
  .home-banner .banner-content h1 > a, .home-banner .banner-content h2 > a, .home-banner .banner-content > span > a {
    padding: 5px 15px;
    font-size: 30px;
  }
  .home-banner .banner-content h1 {
    margin-bottom: 30px;
    font-size: 35px;
  }
  .home-banner .banner-content h2 {
    margin-bottom: 40px;
    font-size: 30px;
  }
}

.text-block {
  text-align: center;
}
.text-block h3 {
  padding: 100px 30px;
  font-size: 50px;
  line-height: 1.3;
}
.text-block h3 span {
  font-weight: bold;
  color: #215D50;
}
.text-block h3 span.yelow {
  color: #FCBA03;
}
@media only screen and (max-width: 991px) {
  .text-block h3 {
    font-size: 25px;
    padding: 40px 15px;
  }
}