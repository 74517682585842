.mobile-nav {
	background:#215D50;
	padding: 20px 30px 20px;
	z-index: 1100;

	a#res-cross {
		position: absolute;
	    top: 15px;
	    right: 20px;
	    color:#fff;
	    width: 40px;
	    height: 40px; 
        display: flex;
    	justify-content: center;
    	align-items: center;
    	font-size: 35px;

    	&:hover {
    		color:#fcba03;
    	}

		&:before {
			display:none;
		}
	}


	> ul {
		> li {
			> a {
				color:#fff;


				&:hover {
					color:#fcba03;
				}
			}
		}
	}

	@media only screen and (max-width: 991px) {
		max-width: 330px;
	}
}

.nav-bar {
	background:  #fcba03;

	.navbar-links {
		li {
			padding: 8px 45px 8px 0;
			&:hover {
				> a {
				    border-bottom: 3px solid #215D50;
				}
			}
		}
	}
}

nav {
	&.navbar {
		> a {
    		color: #000;

    		svg {
    			fill: #215d50;
    		}
		}
	}
} 