.home-banner {
	
	position: relative;
	height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

	&:before {
		content: '';
		display:block;
		position: absolute;
		width: 100%;
		background: url('../img/home-banner-img.jpg') center center no-repeat;
		background-size: cover; 
		height: 100%;
		opacity: 0.6;
		z-index: 1;
	}

	.banner-content {
		padding: 40px 0; 
		display: flex;
		flex-direction: column;
	    align-content: center;
	    align-items: center;
	    



	    h1, h2, > span {
	    	text-align:center;
	    	text-transform: uppercase;
	    	position: relative;
	    	font-weight:bold;
	    	z-index:100;
	  		
	  		> span  {
	  			position: relative;
	  			z-index: 100;
	  			box-shadow: inset 0 -0.4em 0 0 #FCBA03;
	  		}

 
	    	> a {
	    		background:#FCBA03;
	    		display:inline-block;
	    		padding: 10px 25px;
	    		font-size:40px;
	    		border-radius: 8px; 

	    		&:hover {
	    			
	    			color:#fff;
	    			background:#215D50;
	    		}
	    	}
	    }

	    h1 {
	    	margin-bottom: 30px;
	    	font-size:60px;
	    }

	    h2 {
	    	margin-bottom: 40px;
	    	font-size:50px;
	    }
	}

	@media only screen and (max-width: 991px) {
		.banner-content { 

			h1, h2, > span { 

			 

		    	> a {  
		    		padding: 5px 15px;
		    		font-size:30px;	 
		    	}
			}


		 	h1 {

		    	margin-bottom: 30px;
		    	font-size:35px;
		    }

		    h2 {
		    	margin-bottom: 40px;
		    	font-size:30px;
		    } 

		   

		}
	}
}

.text-block {
	text-align:center;

	h3 {
		padding: 100px 30px;
		font-size: 50px;
		line-height: 1.3;

		span {
			font-weight:bold;

			&.yelow {
				color:#FCBA03;	
			}
			color:#215D50
		}

	}

	@media only screen and (max-width: 991px) {
		h3 {
			font-size:25px;
			padding: 40px 15px;
			span {

			}
		}
	}
}